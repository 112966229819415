@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url(//fonts.googleapis.com/earlyaccess/SpoqaHanSansNeo.css); */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url(styles/fonts/NotoSansKR-Thin.woff2) format('woff2'),
    url(styles/fonts/NotoSansKR-Thin.woff) format('woff'),
    url(styles/fonts/NotoSansKR-Thin.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url(styles/fonts/NotoSansKR-Light.woff2) format('woff2'),
    url(styles/fonts/NotoSansKR-Light.woff) format('woff'),
    url(styles/fonts/NotoSansKR-Light.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url(styles/fonts/NotoSansKR-Regular.woff2) format('woff2'),
    url(styles/fonts/NotoSansKR-Regular.woff) format('woff'),
    url(styles/fonts/NotoSansKR-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url(styles/fonts/NotoSansKR-Medium.woff2) format('woff2'),
    url(styles/fonts/NotoSansKR-Medium.woff) format('woff'),
    url(styles/fonts/NotoSansKR-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url(styles/fonts/NotoSansKR-Bold.woff2) format('woff2'),
    url(styles/fonts/NotoSansKR-Bold.woff) format('woff'),
    url(styles/fonts/NotoSansKR-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url(styles/fonts/NotoSansKR-Black.woff2) format('woff2'),
    url(styles/fonts/NotoSansKR-Black.woff) format('woff'),
    url(styles/fonts/NotoSansKR-Black.otf) format('opentype');
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  /* background: #fafafa; */
}

body {
  margin: 0;
  font-family: -apple-system, 'Noto Sans KR', BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* matrial ui */
.MuiDialogActions-root {
  padding: 0 !important;
}

/* toggle */
input ~ .toggle-on {
  display: none;
}
input:checked ~ .toggle-off {
  display: block;
}
input:checked ~ .toggle-dot {
  transform: translateX(200%);
}
input:checked ~ .toggle-bg {
  background-color: green;
}
input:checked ~ .toggle-on {
  display: block;
}
input:checked ~ .toggle-off {
  display: none;
}

/* 
.Toastify__toast--error {
  border: 1px solid #eb5757;
  border-radius: 50px !important;
  background: #fae1e2 !important;
}
.Toastify__toast--error::after {
  content: url('./assets/images/closeToast.svg');
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
.Toastify__toast--error::before {
  content: url('./assets/images/errorToast.svg');
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
*/
/*
.Toastify__toast--success {
  border: 1px solid #3a9ea5 !important;
  border-radius: 50px !important;
  background: #f0f9fa !important;
}
.Toastify__toast--success::before {
  content: url('./assets/images/successToast.svg');
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--success::after {
  content: url('./assets/images/closeToast.svg');
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
*/
/*
.Toastify__toast--warning {
  border: 1px solid #e78326 !important;
  border-radius: 50px !important;
  background: #fadfc5 !important;
}
.Toastify__toast--warning::before {
  content: url('./assets/images/warnToast.svg');
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--warning::after {
  content: url('./assets/images/closeToast.svg');
  position: absolute;
  color: #e78326;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
.Toastify__toast-body {
  color: #444c63;
  font-size: 16px;
  padding-left: 20px;
  line-height: 20px;
  padding: 0px;
  padding-top: 25px;
  width: 100%;
  font-weight: 400;
  margin-left: 25px !important;
}
.Toastify__toast > button > svg {
  display: none;
} */
